import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

import { FEContentCategory } from ':dapp/pages/home/types/homeTypes';

export const OnChainExperiencesParamKey = 'experience';

export function useActiveExperiencesTab() {
  const router = useRouter();
  const searchParams = useSearchParams();

  const handleTabChange = useCallback(
    (experience: string) => {
      router.push({ query: { ...router.query, [OnChainExperiencesParamKey]: experience } });
    },
    [router],
  );

  return useMemo(() => {
    const tabType = searchParams?.get(OnChainExperiencesParamKey);

    return {
      activeTab: tabType && isContentCategoryLabel(tabType) ? tabType : FEContentCategory.TRENDING,
      onTabChange: handleTabChange,
    };
  }, [searchParams, handleTabChange]);
}

export function isContentCategoryLabel(str: string): str is FEContentCategory {
  return Object.values(FEContentCategory).some((enumValue) => enumValue === str);
}
