import { defineMessages, useIntl } from 'react-intl';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { VStack } from '@cbhq/cds-web/layout';

import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';

import { HomeOutage } from '../components/HomeOutage';
import { HomePageWrapper } from '../components/HomePageWrapper';
import { HomeSectionHeader } from '../components/HomeSectionHeader';

import { ChallengeGrid } from './components/ChallengeGrid';
import { ExperiencesFilters } from './components/ExperiencesFilters';

const messages = defineMessages({
  exploreOnchainExperiencesSectionHeaderTitle: {
    defaultMessage: 'Onchain experiences',
    description: "section header title for Onchain Summer's Onchain experiences section",
  },
  exploreOnchainExperiencesSectionHeaderSubtitle: {
    defaultMessage: 'Explore trending experiences onchain',
    description: "section header subtitle for Onchain Summer's Onchain experiences section",
  },
});

function HomeExplorePage() {
  const { formatMessage } = useIntl();
  const { exploreApiIssue } = usePageOutageContext();

  return (
    <VStack testID="home-tab-explore">
      <HomePageWrapper>
        <HomeSectionHeader
          title={formatMessage(messages.exploreOnchainExperiencesSectionHeaderTitle)}
          subtitle={formatMessage(messages.exploreOnchainExperiencesSectionHeaderSubtitle)}
          spacingBottom={1}
        />
        <ExperiencesFilters />

        {exploreApiIssue ? <HomeOutage /> : <ChallengeGrid />}
      </HomePageWrapper>
    </VStack>
  );
}

export default withFeatureEnabled(HomeExplorePage, 'explore_pages');
