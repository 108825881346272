import { defineMessages, useIntl } from 'react-intl';

import { FEContentCategory } from ':dapp/pages/home/types/homeTypes';

export const BASE_TEST_ID = 'home-explore-experiences-tabs';

const messages = defineMessages({
  [FEContentCategory.GAMES]: {
    defaultMessage: 'Games',
    description: 'label for a filter button for the Onchain Summer challenges',
  },
  [FEContentCategory.MEDIA]: {
    defaultMessage: 'Media',
    description: 'label for a filter button for the Onchain Summer challenges',
  },
  [FEContentCategory.SOCIAL]: {
    defaultMessage: 'Social',
    description: 'label for a filter button for the Onchain Summer challenges',
  },
  [FEContentCategory.FINANCE]: {
    defaultMessage: 'Finance',
    description: 'label for a filter button for the Onchain Summer challenges',
  },
  [FEContentCategory.CREATORS]: {
    defaultMessage: 'Creators',
    description: 'label for a filter button for the Onchain Summer challenges',
  },
  [FEContentCategory.TRENDING]: {
    defaultMessage: 'Trending',
    description: 'label for a filter button for the Onchain Summer challenges',
  },
});

export function useExperiencesTabs() {
  const { formatMessage } = useIntl();
  const obj = Object.values(FEContentCategory).map((label: FEContentCategory) => ({
    id: label,
    label: formatMessage(messages[label], {
      experience: label,
    }),
    testID: `${BASE_TEST_ID}--${label}`,
  }));
  return obj;
}
