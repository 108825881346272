import { useMemo } from 'react';
import { CustomTabProps } from '@cbhq/cds-common';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { Chip, TabbedChips } from '@cbhq/cds-web/chips';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextLabel1 } from '@cbhq/cds-web/typography';
import { useActiveExperiencesTab } from ':dapp/pages/home/explore/hooks/useActiveExperiencesTab';
import { useExperiencesTabs } from ':dapp/pages/home/explore/hooks/useExperiencesTabs';
export const testID = 'experiences-filters';
export function ExperiencesFilters() {
  const {
    activeTab,
    onTabChange
  } = useActiveExperiencesTab();
  const experiencesTabs = useExperiencesTabs();
  return <VStack zIndex={zIndex.interactable} spacingVertical={2} testID={`${testID}`}>
      <TabbedChips gap={1} tabs={experiencesTabs} value={activeTab} onChange={onTabChange} Component={ExperienceChip} />
    </VStack>;
}
function ExperienceChip(props: CustomTabProps) {
  const {
    label,
    active
  } = props;
  const chipContainerClass = useMemo(() => {
    if (active) {
      return "c17hz8dt";
    }
    return undefined;
  }, [active]);
  return <Box className={chipContainerClass}>
      <Chip testID={`${testID}-chip`} borderRadius="roundedXLarge">
        <TextLabel1 as="p">{label}</TextLabel1>
      </Chip>
    </Box>;
}

require("./ExperiencesFilters.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ExperiencesFilters.tsx");