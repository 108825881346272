import { useEffect, useRef } from 'react';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { Grid } from '@cbhq/cds-web/layout';

import { CardWithImageLoading } from ':dapp/components/Card/CardWithImage';
import { ExperienceCard } from ':dapp/pages/home/components/ExperienceCard';
import { useHomeOnchainExperiences } from ':dapp/pages/home/hooks/useHomeOnchainExperiences';
import { labelToContentCategory } from ':dapp/pages/home/types/homeTypes';

import { useActiveExperiencesTab } from '../hooks/useActiveExperiencesTab';

export function ChallengeGrid() {
  const { activeTab } = useActiveExperiencesTab();
  const { setExploreApiIssue } = usePageOutageContext();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useHomeOnchainExperiences(labelToContentCategory[activeTab], setExploreApiIssue);
  const challenges = data?.pages.flatMap((page) => page.contentData) || [];

  const observerRef = useRef(null);
  useEffect(
    function observeLastItem() {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        },
        { root: null, rootMargin: '0px', threshold: 1.0 },
      );

      const observed = observerRef.current;
      if (observed) {
        observer.observe(observed);
      }

      return () => {
        if (observed) {
          observer.unobserve(observed);
        }
      };
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage],
  );

  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(350px, 1fr))"
      gap={3}
      spacingBottom={4}
      testID="explore-challenge-grid"
    >
      {challenges.map((contentComponent, index) => (
        <div
          ref={index === challenges.length - 1 ? observerRef : undefined}
          key={`home-explore-tab-cards-${contentComponent.id}`}
        >
          <ExperienceCard card={contentComponent} />
        </div>
      ))}
      {(isLoading || isFetchingNextPage) &&
        [1, 2, 3, 4, 5, 6].map((i) => (
          <CardWithImageLoading key={`card-with-image-loading-${i}`} />
        ))}
    </Grid>
  );
}
