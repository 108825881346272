import { useMemo } from 'react';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { Group } from '@cbhq/cds-web/layout';

import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';
import { HomeTabKey, useHomeTabs } from ':dapp/pages/home/hooks/useHomeTabs';

import { HomeOutage } from './components/HomeOutage';
import HomeExplorePage from './explore/index.page';
import HomeTrendingPage from './trending/index.page';

function HomePage() {
  const tabs = useHomeTabs();
  const { exploreApiIssue } = usePageOutageContext();
  return useMemo(() => {
    if (exploreApiIssue) {
      return <HomeOutage />;
    }

    if (!tabs || tabs.length === 0) return null;

    let page = null;
    switch (tabs[0].id) {
      case HomeTabKey.TRENDING:
        page = <HomeTrendingPage />;
        break;
      case HomeTabKey.EXPLORE:
        page = <HomeExplorePage />;
        break;
      default:
        break;
    }

    return <Group testID="home-page">{page}</Group>;
  }, [exploreApiIssue, tabs]);
}

export default withFeatureEnabled(HomePage, 'explore_pages');
